import { ErrorState as Error } from '@do/walrus';
import React from 'react';
import MetaTags from '../components/MetaTags';
import Main from '../layouts/Main';

const Error404: React.FC = () => (
  <>
    <MetaTags title="Error" />
    <Main>
      <Main.Page>
        <Error statusCode={404} />
      </Main.Page>
    </Main>
  </>
);

export default Error404;
